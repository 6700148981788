import type { ComponentType } from "react"

interface WithFetchPriorityHighProps {
    background: {
        src: string
        srcSet?: string
        sizes?: string
        [key: string]: any
    }
    [key: string]: any // for any additional props
}

export function defaultWithFetchPriority<P extends object>(
    Component: ComponentType<P>
): ComponentType<P & WithFetchPriorityHighProps> {
    return (props: P & WithFetchPriorityHighProps) => {
        return (
            <>
                <Component
                    {...props}
                    background={{
                        ...props.background,
                        loading: "lazy",
                    }}
                    loading="lazy"
                />
            </>
        )
    }
}

export function withFetchPriorityHigh<P extends object>(
    Component: ComponentType<P>
): ComponentType<P & WithFetchPriorityHighProps> {
    return (props: P & WithFetchPriorityHighProps) => {
        return (
            <>
                <Component
                    {...props}
                    background={{
                        ...props.background,
                        fetchPriority: "high",
                    }}
                    fetchPriority="high"
                />
            </>
        )
    }
}
